export default {
  'Charging Your Bottle': {
    heading: `Carga de tu frasco inteligente Aidia`,
  },
  'Charging instructions': {
    heading: `Instrucciones de carga`,
    content: `Si has recibido un mensaje de batería baja de nuestra parte, es hora de recargar tu frasco inteligente Aidia. Sigue estas sencillas instrucciones.`,
  },
  'Charging instruction 1': {
    content: `Localiza el cargador que te llegó en la caja con tu frasco inteligente Aidia.`,
  },
  'Charging instruction 2': {
    content: `Levanta la tapa trasera de goma en la parte inferior del frasco para revelar el puerto de carga. Inserta el cargador y conéctalo a una toma de corriente.`,
  },
  'Charging instruction 3': {
    content: `Una vez enchufado y cargado, el frasco brillará en color <span style="color: #7450EF;">MORADO</span>. Cuando esté completamente cargado, se iluminará en <span style="color: #67A300;">VERDE</span>. Todas las alertas de dosis funcionarán normalmente durante la carga. Tiempo de recarga: dentro de las 8 horas.`,
  },
  'Replacement charger': {
    heading: `Cargador de repuesto`,
    content: `Si pierdes el cargador o no recibiste uno con tu botella inteligente, te enviaremos uno de reemplazo sin costo alguno. Llama al número que figura en la parte inferior de tu frasco o haz clic a continuación para solicitar un cargador.`,
    cta: `Solicitar cargador`,
  },
  'Replacement charger_onco': {
    heading: `Cargador de repuesto`,
    content: `Si pierdes el cargador o no recibiste uno con tu botella inteligente, te enviaremos uno de reemplazo sin costo alguno. Simplemente llame a la farmacia Onco360 Oncology al (877) 662-6633 para solicitar un cargador.`,
  },
  Questions: {
    heading: `¿Preguntas?`,
    content: `Consulte estas preguntas frecuentes.`,
    cta: `Ver preguntas frecuentes`,
  },
}
