export default {
  'Charging Your Bottle': {
    heading: `Charging Your Aidia Smart Bottle`,
  },
  'Charging instructions': {
    heading: `Charging instructions`,
    content: `If you have received a low battery message from us – it’s time to recharge your Aidia Smart Bottle. Follow these simple instructions.`,
  },
  'Charging instruction 1': {
    content: `Locate the charger that arrived in the box with your Aidia Smart Bottle.`,
  },
  'Charging instruction 2': {
    content: `Lift the black rubber flap on the bottom of the bottle to reveal the charging port. Insert the charger and plug into a power outlet.`,
  },
  'Charging instruction 3': {
    content: `Once plugged in and charging, the bottle will glow <span style="color: #7450EF;">PURPLE</span>. When it’s fully charged it will glow <span style="color: #67A300;">GREEN</span>. All dose alerts will work normally during charging. Recharge time: within 8 hours.`,
  },
  'Replacement charger': {
    heading: `Replacement charger`,
    content: `If your charger is lost or you did not receive one with your smart bottle, we will send a replacement at no cost to you. Call the number on the bottom of your bottle or click below to request a charger.`,
    cta: `Request charger`,
  },
  'Replacement charger_onco': {
    heading: `Replacement charger`,
    content: `If your charger is lost or you did not receive one with your smart bottle, we will send a replacement at no cost to you. To request a replacement, contact Onco360 Oncology Pharmacy at (877) 662-6633.`,
  },
  Questions: {
    heading: `Questions?`,
    content: `Check out these frequently asked questions.`,
    cta: `View FAQs`,
  },
}
