import React from 'react'

const NumberedList = ({ textElements }) => {
  return (
    <div className="numbered-list">
      {textElements.map((textElement, i) => {
        return (
          <div className="numbered-list-item" key={`listItem_${i + 1}`}>
            <div className="numbered-list-number">{i + 1}</div>
            <div className="numbered-list-text">{textElement}</div>
          </div>
        )
      })}
    </div>
  )
}

export default NumberedList
