import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { getSearchParams } from 'gatsby-query-params'

import LanguageTabs, { useLang } from '../components/LanguageTabs'
import charging_EN from '../content/landingPages/charging_EN'
import charging_ES from '../content/landingPages/charging_ES'
import NumberedList from '../components/NumberedList'
import CallToActionLink from '../components/CallToActionLink'

const content = {
  EN: charging_EN,
  ES: charging_ES,
}

const ChargingLandingPage = () => {
  const queryParams = getSearchParams()
  const { sp } = queryParams
  const lang = useLang(content)

  const getFAQUrl = () => {
    const paramsStr = new URLSearchParams(queryParams).toString()
    return paramsStr ? `/faqs-bottle?${paramsStr}` : '/faqs-bottle'
  }

  return (
    <main className="charging-landing-page">
      <div className="welcome-hero">
        <div className="welcome-hero-text">{lang('Charging Your Bottle').heading}</div>
      </div>

      <div className="landing-page-section">
        <div className="landing-page-section-content">
          <LanguageTabs />
          <div className="intro">
            <h2>{lang('Charging instructions').heading}</h2>
            <p>{lang('Charging instructions').content}</p>
          </div>

          <div className="numbered-list-container">
            <div className="left-column">
              <NumberedList
                textElements={[
                  <p>{lang('Charging instruction 1').content}</p>,
                  <p>{lang('Charging instruction 2').content}</p>,
                  <p dangerouslySetInnerHTML={{ __html: lang('Charging instruction 3').content }}></p>,
                ]}
              />
              <div className="bottle-charging-diagram">
                <StaticImage
                  src="../images/bottle-charging-diagram.png"
                  alt="Bottle charging diagram"
                  placeholder="none"
                  layout="fixed"
                  width={233}
                />
              </div>
            </div>
            <div className="right-column">
              <StaticImage
                src="../images/recharge-bottle.png"
                alt="Bottle charging closeup"
                placeholder="none"
                layout="constrained"
                height={260}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="landing-page-section-dark">
        <div className="landing-page-section-content">
          <div className="replacement-charger">
            <h3>{lang('Replacement charger').heading}</h3>
            <p>{sp === 'onco' ? lang('Replacement charger_onco').content : lang('Replacement charger').content}</p>
            {sp !== 'onco' && (
              <CallToActionLink
                linkText={lang('Replacement charger').cta}
                external
                linkUrl="https://docs.google.com/forms/d/1ylpRYluZK1vbJ28s8pdl7OHVR0Xr_nx422cCeZHOGUE/edit?usp=sharing_eil_se_dm&ts=631f712a"
              />
            )}
          </div>
        </div>
      </div>

      <div className="faqs-wrapper">
        <div className="faqs">
          <h3>{lang('Questions').heading}</h3>
          <p>{lang('Questions').content}</p>
          <CallToActionLink linkText={lang('Questions').cta} linkUrl={getFAQUrl()} />
        </div>
      </div>
    </main>
  )
}

export default ChargingLandingPage
